
import {Prop, Vue, Component} from 'vue-property-decorator';

@Component
export default class ButtonGiveaway extends Vue {
  @Prop({type: Boolean, default: false}) readonly isInGiveaway!: boolean;
  @Prop({type: Boolean, default: false}) readonly metConditions!: boolean;
  @Prop({type: String, required: true}) readonly giveawayType!: 'refillBalance' | 'completeEventTasks' | 'usePromotion';
  @Prop({type: Boolean, required: false}) readonly forceUseGiveaway!: boolean;

  openRefill() {
    this.$store.dispatch('modal/toggleModal', {name: 'refill'});
  }

  handleJoinGiveaway() {
    if (this.isOnMainPage() && this.giveawayType === 'completeEventTasks') {
      this.$router.push('/event');
    } else {
      this.$emit('join-giveaway');
    }
  }

  isOnMainPage(): boolean {
    return this.$route.path === '/';
  }
}
