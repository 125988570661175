
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { VueAgile } from 'vue-agile';

import Button from '../Buttons/Button.vue';
import TaskInfo from './Task/Info.vue';
import Reward from './Task/Reward.vue';
import TaskPreview from './Task/TaskPreview.vue';
import { EventTask } from '@/types/Event';
import { Item } from '@/types/Item';
import { ruDeclension } from '@/helpers/ruDeclension';

const ARCANE_LVLS = [15, 30, 45];
const HOOK_LVL = 60;

@Component({
  components: {Reward, TaskInfo, TaskPreview, Button, agile: VueAgile},
})
export default class UserTasks extends Vue {
  @Prop({type: Boolean, default: false}) fakeVersion: boolean;

  interval: any = null;

  agileOptions = {
    dots: false,
    infinite: false,
    initialSlide: 0,
    navButtons: false,
    responsive: [
      {
        breakpoint: 2560,
        settings: {
          slidesToShow: 21,
        },
      },
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 16,
        },
      },
      {
        breakpoint: 1699,
        settings: {
          slidesToShow: 12,
        },
      },
      {
        breakpoint: 1480,
        settings: {
          slidesToShow: 11,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 9,
        },
      },
      {
        breakpoint: 959,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 779,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 369,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  async mounted() {
    this.scrollToCurrentTask();
    this.interval = setInterval(() => {
      this.$store.dispatch('event/fetchTasks', {refetch: true});
    }, 7 * 1000);
  }

  beforeDestroy() {
    clearInterval(this.interval);
  }

  moveSlider(side: 'left' | 'right') {
    const refSlider: any = this.$refs.agileCarousel;
    if (side === 'left') {
      if (refSlider.getCurrentSlide() > 0) {
        refSlider.goToPrev();
      }
    }
    if (side === 'right') {
      const {slidesToShow} = refSlider.getCurrentSettings();
      if (refSlider.getCurrentSlide() + slidesToShow <= this.tasks.length - 1) {
        refSlider.goToNext();
      }
    }
  }

  get tasks(): EventTask[] {
    return this.$store.state.event.tasks.data.tasks;
  }

  get rewardItems(): Item[] {
    return this.$store.state.event.tasks.data.rewardItems;
  }

  get windowWidth() {
    return window.innerWidth;
  }

  onTaskCompleted() {
    this.$store.dispatch('event/completeCurrentTask');
  }

  get currentTaskIndex() {
    return this.fakeVersion ? 14 : this.tasks.findIndex(item => item.current);
  }

  get lvlUntilArcane() {
    let arcaneLvl = ARCANE_LVLS[0];
    if (this.currentTaskIndex + 1 > 14) {
      arcaneLvl = ARCANE_LVLS[1];
    }
    if (this.currentTaskIndex + 1 > 29) {
      arcaneLvl = ARCANE_LVLS[2];
    }

    return (
        arcaneLvl - (this.currentTaskIndex === -1 ? 0 : this.currentTaskIndex + 1)
    );
  }

  @Watch('currentTaskIndex')
  onSelectedTaskIndexChange() {
    this.scrollToCurrentTask();
  }

  scrollToCurrentTask() {
    setTimeout(() => {
      const refSlider: any = this.$refs.agileCarousel;
      const {slidesToShow} = refSlider.getCurrentSettings();

      let offset;
      switch (slidesToShow) {
        case 1:
          offset = 0;
          break;
        default:
          offset = 1;
      }

      let startingSlideIndex =
          this.currentTaskIndex - offset >= 0
              ? this.currentTaskIndex - offset
              : offset - this.currentTaskIndex - 1;

      if (startingSlideIndex < offset) {
        startingSlideIndex = 0;
      }

      if (startingSlideIndex + slidesToShow >= this.tasks.length) {
        startingSlideIndex = this.tasks.length - slidesToShow;
      }

      if (this.fakeVersion)
        startingSlideIndex = 15 - Math.round(slidesToShow / 2);

      refSlider.goTo(startingSlideIndex);
    }, 100);
  }

  get caseToDisplayInRewards() {
    return [2, 3].includes(this.currentTaskIndex) && this.tasks[this.currentTaskIndex].conditions.openCases
        ? this.tasks[this.currentTaskIndex].conditions.openCases[0]
        : null;
  }

  get lvlUntilHook() {
    return (
        HOOK_LVL - (this.currentTaskIndex === -1 ? 0 : this.currentTaskIndex + 1)
    );
  }

  get isDebugMode() {
    return process.env.NODE_ENV === 'development';
  }

  ruDec(val: number, strings: string[]) {
    return ruDeclension(val, strings);
  }
}
